import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import {BREAKPOINT} from '@/utils/constants';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { graphql } from 'gatsby';
import { FeaturesSlider } from '@latitude/features-slider';
import { Accordion } from '@latitude/accordion';
import { ImportantInformation } from '@latitude/important-information';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import Layout from '@/components/layout';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import ManageYourLoan from '@/components/ManageYourLoan/ManageYourLoan';
import Text from '@/components/Text/Text';
import footerData from '@/data/json/footer.json';
import heroImage from '../images/hero/loans.webp';
import PageData from '../data/pages/loans.json';
import PLData from '../data/pages/personal-loan.json';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { NotificationBanner } from '@latitude/banner';
import { AnalyticsContext } from 'latitude-analytics';
import ProductComparisonSection from '@/components/PersonalLoanPageContent/ProductComparisonSection';
import CardCollection from '@/components/lab-components/CardCollection';
import ProofPointCollection from '@/components/lab-components/ProofPointCollection';

const LoansPage = props => {

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'Why choose a Latitude loan?',
      featureCards: PLData.content.whyChooseLatitude
    }
  ];
  // console.log(state)
  /** - END - */

  const [analytics] = useContext(AnalyticsContext);

  // adding pageData to analytics
  analytics.pageData = {
    page_pageTitle: 'loans',
    page_pageType: 'category-page',
    page_siteSection: 'loans',
    page_siteSubsection: 'loans',
    product_productid: ['PLAULF-WEB']
  };

  function transformData(data) {
    return {
      title: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'heading-3',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: 'Recommended reads',
                marks: [],
                data: {}
              }
            ]
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: '',
                marks: [],
                data: {}
              }
            ]
          }
        ]
      },
      cards: data?.map(item => ({
        image: {
          file: {
            url: `/life-done-better/images/${item?.node?.frontmatter?.thumbnail}`
          }
        },
        title: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: item?.node?.frontmatter?.title,
                  nodeType: 'text'
                }
              ],
              nodeType: 'paragraph'
            }
          ],
          nodeType: 'document'
        },
        tileUrl: item?.node?.frontmatter?.path
      }))
    };
  }

  return (
    <Layout location={props.location} customFooter={footerData}>
      <MobileAppInstallPrompts />
      <main
        className="navigation-spacer"
        css={`
          background-color: #f8f8f8;
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/loans/"
            hreflang="x-default"
          />
          <meta
            name="description"
            content="We offer loan types to suit many situations, including buying a car, renovating a home or travelling. Explore our personal loans & vehicle finance options."
          />
          <title>Latitude Loans | Latitude Financial</title>
        </Helmet>
        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            title="Latitude Loans"
            subTitle={
              <>
                Need money for a shiny new car, an overseas adventure, or
                simplifying your repayments? Whatever your goals, we’ll try and
                get you there. Explore your options below.
              </>
            }
            pageImage={heroImage}
            pageImageAlt=""
          />
        )}
        <div className="d-none d-lg-block" css="position:relative; z-index:10;">
          <StickyNavigationBranded
            items={PageData.nav}
            offsetElem="[data-sticky-navigation-offset]"
            {...state?.inPageNavData?.[0]}
          />
        </div>

        <FeaturesSlider
          key={(state?.featureSliderData?.[0] || featureSliderData[0]).heading}
          id="why-us"
          data={
            (state?.featureSliderData?.[0] || featureSliderData[0]).featureCards
          }
          className="pt-4 why-choose pt-md-5"
          heading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).heading
          }
          subheading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).description
          }
          css={`
            background-color: #e6e6e6;
            && ul {
              text-align: left;
              @media (max-width: ${BREAKPOINT.MD}) {
                padding: 2px 20px 20px 20px;
                text-align: center;
                list-style-position: inside;
              }
            }
            && h4 {
              text-align: left;
              font-size: 32px;
              line-height: 36px;
              margin: 0px;
              @media (max-width: ${BREAKPOINT.MD}) {
                text-align: center;
              }
            }
            &&.lfs-features-slider {
              padding: 32px 0px;
            }
            && h4 > p {
              padding: 0px;
              margin: 0px;
            }
            && div.lfs-card-icon {
              padding: 0;
              width: 120px !important;
              height: 120px !important;
              @media (max-width: ${BREAKPOINT.MD}) {
                margin-bottom: 0px;
              }
            }
            && svg {
              border-radius: 50%;
              background-color: white;
              padding: 0px 23px;
            }
            && .lfs-card-text {
              @media (max-width: ${BREAKPOINT.MD}) {
                margin-top: 0px;
              }
            }
            && .lfs-card {
              @media (max-width: ${BREAKPOINT.MD}) {
                gap: 16px;
              }
            }
          `}
        />
        <ProductComparisonSection
          id="compare-products"
          heading="Compare our fixed and variable loan options to find the right fit"
        />
        <ProofPointCollection
          id="calculate"
          data={
            state?.proofPointCollectionData?.[0] ||
            PageData?.contentful?.proofPointCollectionData?.[0]
          }
        />
        <CardCollection
          id="life-cant-wait"
          data={
            state?.cardCollectionData?.[0] ||
            PLData?.contentful?.cardCollectionData?.[0]
          }
        />

        <div
          id="faq"
          data-contentful={state?.faqData?.[0]?.contentfulID}
          css={`
            && {
              background-color: #f8f8f8;
              padding-top: 10px;
              padding-bottom: 0;
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-top: 42px;
                padding-bottom: 0;
              }
            }
            .section__content > div::after {
              border: none;
            }
          `}
        >
          <AnalyticsLocationProvider
            location={
              state?.faqData?.[0]?.title || 'Let’s look at the nuts and bolts'
            }
          >
            <ManageYourLoan
              heading={
                state?.faqData?.[0]?.title || 'Let’s look at the nuts and bolts'
              }
              description={
                state?.faqData?.[0]?.description ||
                'Let’s do this together, answering all your questions, helping you manage your loan.'
              }
            >
              <Accordion
                titleBgColor="grey-light"
                items={
                  state?.faqData?.[0]?.data || [
                    ({
                      id: 'item-1',
                      title: 'How much can I borrow?',
                      // initiallyActive: true,
                      content: (
                        <Text>
                          The minimum amount you can borrow for a Latitude
                          Personal Loan is $5,000, with the maximum depending on
                          your circumstances and current financial situation.
                        </Text>
                      )
                    },
                    {
                      id: 'item-2',
                      title: 'When will I get a decision on my application?',
                      content: (
                        <Text>
                          In most cases, you can expect to receive a decision on
                          your loan application in 1-2 working days.
                        </Text>
                      )
                    },
                    {
                      id: 'item-3',
                      title: 'How long until I receive my funds?',
                      content: (
                        <Text>
                          Once your loan is approved and signed, your funds are
                          usually available the next day or no more than two
                          working days (depending on your circumstances).
                        </Text>
                      )
                    })
                  ]
                }
              />
            </ManageYourLoan>
          </AnalyticsLocationProvider>
        </div>

        <CardCollection
          id="Recommended reads"
          data={
            state?.cardCollectionData?.[1] ||
            transformData(pageQuery?.allMarkdownRemark?.edges)
          }
          category="text-link"
        />

        <ImportantInformation
          data={require('../data/json/disclaimer/personal-loan.json')}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          additionalPaymentsDisclaimerOne
          {...state?.importantInfoData?.[0]}
          css={`
            .important-info svg {
              stroke-width: unset;
            }
            .important-info__header > h3 {
              @media (min-width: 992px) {
                margin: 0 0 0 6px;
              }
            }
          `}
        />
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
          glob: "**/src/templates/life-done-better/articles/*.md"
        }
        frontmatter: {
          title: {
            in: [
              "How to Consolidate Debt: The All-in-One Guide"
              "Is a Debt Consolidation Loan Right for You?"
              "Tips to Help You Become a Super Saver"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            thumbnail
          }
        }
      }
    }
  }
`;

export default LoansPage;
